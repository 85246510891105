var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isOpen
    ? _c("div", { staticClass: "search-box-modal row m-0" }, [
        _c(
          "div",
          {
            staticClass: "search-header align-items-center",
            attrs: { "data-epica-module-name": "Header" },
          },
          [
            _c(
              "button",
              {
                staticClass: "close",
                attrs: { type: "button" },
                on: { click: _vm.close },
              },
              [_c("i", { staticClass: "icon-close" })]
            ),
            _vm._v(" "),
            _c("a", {
              staticClass: "icon-logo-base-lr-small",
              attrs: { href: "/" },
            }),
            _vm._v(" "),
            _c("form", { attrs: { action: "/buscar" } }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.topic,
                    expression: "topic",
                  },
                ],
                ref: "topic",
                attrs: {
                  name: "term",
                  type: "search",
                  placeholder: "Buscar en La República",
                  autocomplete: "off",
                  autocorrect: "off",
                  autocapitalize: "off",
                  spellcheck: "false",
                },
                domProps: { value: _vm.topic },
                on: {
                  keydown: [
                    function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "up", 38, $event.key, [
                          "Up",
                          "ArrowUp",
                        ])
                      ) {
                        return null
                      }
                      $event.preventDefault()
                      return _vm.inputUp($event)
                    },
                    function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "down", 40, $event.key, [
                          "Down",
                          "ArrowDown",
                        ])
                      ) {
                        return null
                      }
                      $event.preventDefault()
                      return _vm.inputDown($event)
                    },
                    function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      ) {
                        return null
                      }
                      $event.preventDefault()
                      return _vm.inputEnter.apply(null, arguments)
                    },
                  ],
                  input: [
                    function ($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.topic = $event.target.value
                    },
                    _vm.autocomplete,
                  ],
                  change: _vm.autocomplete,
                },
              }),
              _vm._v(" "),
              _c(
                "button",
                {
                  ref: "dosearch",
                  staticClass: "search",
                  attrs: { disabled: !_vm.topic },
                },
                [_c("i", { staticClass: "icon-search" })]
              ),
              _vm._v(" "),
              _c(
                "button",
                { attrs: { type: "button" }, on: { click: _vm.close } },
                [_vm._v("Cancelar")]
              ),
            ]),
            _vm._v(" "),
            _vm.acResult
              ? _c(
                  "div",
                  {
                    staticClass: "autocomplete-results",
                    attrs: {
                      "data-epica-module-name": "Autocomplete Caja Búsqueda",
                      "data-mrf-recirculation": "Autocomplete Caja Búsqueda",
                    },
                  },
                  [
                    _vm.acResult.tag && _vm.acResult.tag.length
                      ? _c("div", { staticClass: "contentType" }, [
                          _c("div", { staticClass: "d-flex" }, [
                            _vm._m(0),
                            _vm._v(" "),
                            _c("div", [
                              _vm._m(1),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "wrap-items" },
                                _vm._l(
                                  _vm.acResult.tag,
                                  function (item, index) {
                                    return _c("div", { key: index }, [
                                      _c("a", {
                                        ref: "result",
                                        refInFor: true,
                                        class: { focus: item.idx == _vm.focus },
                                        attrs: { href: item.url },
                                        domProps: {
                                          innerHTML: _vm._s(item.title),
                                        },
                                      }),
                                    ])
                                  }
                                ),
                                0
                              ),
                            ]),
                          ]),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.acResult.author && _vm.acResult.author.length
                      ? _c("div", { staticClass: "contentType" }, [
                          _c("div", { staticClass: "d-flex" }, [
                            _vm._m(2),
                            _vm._v(" "),
                            _c("div", [
                              _vm._m(3),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "wrap-items" },
                                _vm._l(
                                  _vm.acResult.author,
                                  function (item, index) {
                                    return _c("div", { key: index }, [
                                      _c("a", {
                                        ref: "result",
                                        refInFor: true,
                                        class: { focus: item.idx == _vm.focus },
                                        attrs: { href: item.url },
                                        domProps: {
                                          innerHTML: _vm._s(item.title),
                                        },
                                      }),
                                    ])
                                  }
                                ),
                                0
                              ),
                            ]),
                          ]),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.acResult.inside && _vm.acResult.inside.length
                      ? _c("div", { staticClass: "contentType" }, [
                          _c("div", { staticClass: "d-flex" }, [
                            _vm._m(4),
                            _vm._v(" "),
                            _c("div", [
                              _vm._m(5),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "wrap-items" },
                                _vm._l(
                                  _vm.acResult.inside,
                                  function (item, index) {
                                    return _c("div", { key: index }, [
                                      _c("a", {
                                        ref: "result",
                                        refInFor: true,
                                        class: { focus: item.idx == _vm.focus },
                                        attrs: { href: item.url },
                                        domProps: {
                                          innerHTML: _vm._s(item.title),
                                        },
                                      }),
                                    ])
                                  }
                                ),
                                0
                              ),
                            ]),
                          ]),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.acResult.podcast && _vm.acResult.podcast.length
                      ? _c("div", { staticClass: "contentType" }, [
                          _c("div", { staticClass: "d-flex" }, [
                            _vm._m(6),
                            _vm._v(" "),
                            _c(
                              "div",
                              [
                                _vm._m(7),
                                _vm._v(" "),
                                _vm._l(
                                  _vm.acResult.podcast,
                                  function (item, index) {
                                    return _c("div", { key: index }, [
                                      _c("a", {
                                        ref: "result",
                                        refInFor: true,
                                        class: { focus: item.idx == _vm.focus },
                                        attrs: { href: item.url },
                                        domProps: {
                                          innerHTML: _vm._s(item.title),
                                        },
                                      }),
                                    ])
                                  }
                                ),
                              ],
                              2
                            ),
                          ]),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.acResult.post && _vm.acResult.post.length
                      ? _c("div", { staticClass: "contentType" }, [
                          _c("div", { staticClass: "d-flex" }, [
                            _vm._m(8),
                            _vm._v(" "),
                            _c(
                              "div",
                              [
                                _vm._m(9),
                                _vm._v(" "),
                                _vm._l(
                                  _vm.acResult.post,
                                  function (item, index) {
                                    return _c("div", { key: index }, [
                                      _c("a", {
                                        ref: "result",
                                        refInFor: true,
                                        class: { focus: item.idx == _vm.focus },
                                        attrs: { href: item.url },
                                        domProps: {
                                          innerHTML: _vm._s(item.title),
                                        },
                                      }),
                                    ])
                                  }
                                ),
                              ],
                              2
                            ),
                          ]),
                        ])
                      : _vm._e(),
                  ]
                )
              : _vm._e(),
          ]
        ),
      ])
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "icon" }, [
      _c("i", { staticClass: "icon-tag" }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h3", [_c("span", [_vm._v("Temas")])])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "icon" }, [
      _c("i", { staticClass: "icon-person" }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h3", [_c("span", [_vm._v("Autores")])])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "icon" }, [
      _c("i", { staticClass: "icon-videocam" }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h3", [_c("span", [_vm._v("Inside")])])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "icon" }, [
      _c("i", { staticClass: "icon-mic" }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h3", [_c("span", [_vm._v("Podcast")])])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "icon" }, [
      _c("i", { staticClass: "icon-file-text" }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h3", [_c("span", [_vm._v("Artículos")])])
  },
]
render._withStripped = true

export { render, staticRenderFns }