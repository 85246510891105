var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("a", { attrs: { href: _vm.quote.url } }, [
    _c("span", {
      staticClass: "nameIndicator",
      domProps: { innerHTML: _vm._s(_vm.quote.name) },
    }),
    _vm._v(" "),
    _c("span", { domProps: { innerHTML: _vm._s(_vm.quote.value) } }, [
      _c("i", {
        class: _vm.quote.upDownClass + " icon-" + _vm.quote.upDownClass,
      }),
    ]),
    _vm._v(" "),
    _c("span", {
      class: _vm.quote.upDownClass,
      domProps: { innerHTML: _vm._s(_vm.quote.absVariation) },
    }),
    _vm._v(" "),
    _c("span", {
      staticClass: "varPor",
      domProps: { innerHTML: _vm._s(_vm.quote.perVariation) },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }