<template>
  <a :href="quote.url">
    <span class="nameIndicator" v-html="quote.name"></span>
    <span v-html="quote.value">
      <i :class="quote.upDownClass + ' icon-' + quote.upDownClass"></i>
    </span>
    <span :class="quote.upDownClass" v-html="quote.absVariation"></span>
    <span class="varPor" v-html="quote.perVariation"></span>
  </a>
</template>

<script>
export default {
  props: {
    quote: Object,
  },
};
</script>
