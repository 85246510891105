import Vue from 'vue';
const state = () => ({
    h: {}
});


const getters = {
    heights: (state) => {
        return state.h;
    },
    heightByGroup: (state) => (groupName) => {
        return state.h[groupName];
    }
};

const actions = {
    setHeight({
        commit,
        getters
    }, {
        groupName,
        height
    }) {
        const h = getters.heightByGroup(groupName) ? Math.min(getters.heightByGroup(groupName), height) : height;
        commit("setHeight", {
            groupName: groupName,
            height: h
        });
    },
    reset({
        commit
    }) {
        commit('reset');
    }
};

const mutations = {
    setHeight(state, {
        groupName,
        height
    }) {
        Vue.set(state.h, groupName, height)
    },
    reset(state) {
        for (var key in state.h) {
            Vue.delete(state.h, key);
        }
    }
};


export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}