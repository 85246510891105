import Vue from 'vue';
import axios from 'axios';
const state = () => ({
    data: null,
    loading: false
});


const getters = {
    data: (state) => {
        return state.data;
    },
    loading: (state) => {
        return state.loading;
    },
    isOn: (getters) => {
        return !!getters.data;
    }
};

const actions = {
    init({
        commit,
        dispatch
    }) {
        this._vm.$refreshHub.$on("live-update", () => {
            dispatch('getLive');
        });
    },
    initLite({
        commit,
        dispatch
    }) {
        this._vm.$refreshHub.$on("live-update", () => {
            dispatch('getLiveLite');
        });
    },
    getLive({
        commit,
        getters
    }) {
        if (!getters.loading) {
            commit('setLoading', true);
            axios.get('/api/live').then(q => {
                if (q.data && q.data.live) {
                    commit('setLive', {
                        data: q.data.live
                    });
                } else {
                    commit('clean');
                }
                commit('setLoading', false);
            })
        }
    },
    getLiveLite({
        commit,
        getters
    }) {
        if (!getters.loading) {
            commit('setLoading', true);
            axios.get('/api/live/lite').then(q => {
                if (q.data && q.data.live) {
                    commit('setLive', {
                        data: q.data.live
                    });
                } else {
                    commit('clean');
                }
                commit('setLoading', false);
            })
        }
    }
};

const mutations = {
    setLoading(state, value) {
        state.loading = value;
    },
    setLive(state, {
        data
    }) {
        Vue.set(state, "data", data);
    },
    clean(state) {
        for (var key in state.data) {
            Vue.delete(state.data, key);
        }
        Vue.delete(state, "data");
    }
};


export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}