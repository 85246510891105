import Vue from "vue";
import axios from "axios";
const state = () => ({
  pres2022_1_historicGraph: null,
  pres2022_2_historicGraph: null,
  pres2022_2_stateGraph: null,
  pres2022_2_bulletin: null,
  terr2023_1_home: null,
  isLoading: [],
  colMap: null,
});

const getters = {
  pres2022_1_historicGraph: (state) => {
    return state.pres2022_1_historicGraph;
  },
  pres2022_2_historicGraph: (state) => {
    return state.pres2022_2_historicGraph;
  },
  pres2022_2_stateGraph: (state) => {
    return state.pres2022_2_stateGraph;
  },
  pres2022_2_bulletin: (state) => {
    return state.pres2022_2_bulletin;
  },
  pres2022_2_enabled: (state) => {
    return state.pres2022_2_bulletin && state.pres2022_2_bulletin.candidates;
  },
  terr2023_1_home: (state) => {
    return state.terr2023_1_home;
  },
  colMap: (state) => {
    return state.colMap;
  },
};

const actions = {
  getPres2022_1_HistoricGraph({ commit, state }, location) {
    if (
      state.isLoading.indexOf("pres2022_1_historicGraph") == -1 &&
      !state.pres2022_1_historicGraph
    ) {
      commit("addLoading", {
        name: "pres2022_1_historicGraph",
      });
      const params = {};
      if (location) {
        params.state = location;
      }
      axios
        .get("/api/elections/pres-2022-1/historic-graph", {
          params: params,
        })
        .then((q) => {
          if (q.data && q.data.datasetValues) {
            commit("setState", {
              name: "pres2022_1_historicGraph",
              data: q.data,
            });
          }
          commit("removeLoading", {
            name: "pres2022_1_historicGraph",
          });
        });
    }
  },
  getPres2022_2_HistoricGraph({ commit, state }, location) {
    if (
      state.isLoading.indexOf("pres2022_2_historicGraph") == -1 &&
      !state.pres2022_2_historicGraph
    ) {
      commit("addLoading", {
        name: "pres2022_2_historicGraph",
      });
      const params = {};
      if (location) {
        params.state = location;
      }
      axios
        .get("/api/elections/pres-2022-2/historic-graph", {
          params: params,
        })
        .then((q) => {
          if (q.data && q.data.datasetValues) {
            commit("setState", {
              name: "pres2022_2_historicGraph",
              data: q.data,
            });
          }
          commit("removeLoading", {
            name: "pres2022_2_historicGraph",
          });
        });
    }
  },
  getPres2022_2_StateGraph({ commit, state }, location) {
    if (
      state.isLoading.indexOf("pres2022_2_stateGraph") == -1 &&
      !state.pres2022_2_stateGraph
    ) {
      commit("addLoading", {
        name: "pres2022_2_stateGraph",
      });
      const params = {};
      if (location) {
        params.location = location;
      }
      axios
        .get("/api/elections/pres-2022-2/state-graph", {
          params: params,
        })
        .then((q) => {
          if (q.data && q.data.datasetValues) {
            commit("setState", {
              name: "pres2022_2_stateGraph",
              data: q.data,
            });
          }
          commit("removeLoading", {
            name: "pres2022_2_stateGraph",
          });
        });
    }
  },
  getPres2022_2_Bulletin({ commit, state }, location) {
    if (
      state.isLoading.indexOf("pres2022_2_bulletin") == -1 &&
      !state.pres2022_2_bulletin
    ) {
      commit("addLoading", {
        name: "pres2022_2_bulletin",
      });
      const params = {};
      if (location) {
        params.state = location;
      }
      axios
        .get("/api/elections/pres-2022-2/bulletin", {
          params: params,
        })
        .then((q) => {
          if (q.data) {
            commit("setState", {
              name: "pres2022_2_bulletin",
              data: q.data,
            });
          }
          commit("removeLoading", {
            name: "pres2022_2_bulletin",
          });
        });
    }
  },
  setPres2022_2_Bulletin({ commit }, data) {
    commit("setState", {
      name: "pres2022_2_bulletin",
      data: data,
    });
  },
  setPres2022_2_StateGraph({ commit }, data) {
    commit("setState", {
      name: "pres2022_2_stateGraph",
      data: data,
    });
  },
  addPres2022_2_HistoricGraph({ commit, state, dispatch }, data) {
    if (state.pres2022_2_historicGraph) {
      commit("addPres2022_2_HistoricGraph", {
        data: data,
      });
    } else {
      dispatch("getPres2022_2_HistoricGraph");
    }
  },
  initMap({ commit, state }) {
    if (state.isLoading.indexOf("col-map") == -1 && !state.colMap) {
      commit("addLoading", {
        name: "col-map",
      });
      axios.get("https://img.lalr.co/maps/col.json").then((q) => {
        commit("setState", {
          name: "colMap",
          data: q.data,
        });
        commit("removeLoading", {
          name: "col-map",
        });
      });
    }
  },
  getTerr2023_1_home({ commit, state }) {
    if (
      state.isLoading.indexOf("terr2023_1_home") == -1 &&
      !state.terr2023_1_home
    ) {
      commit("addLoading", {
        name: "terr2023_1_home",
      });
      axios
        .get("/api/elections/2023/home")
        .then((q) => {
          if (q.data) {
            commit("setState", {
              name: "terr2023_1_home",
              data: q.data,
            });
          }
          commit("removeLoading", {
            name: "terr2023_1_home",
          });
        });
    }
  },
  setTerr2023_1_home({ commit }, data) {
    commit("setState", {
      name: "terr2023_1_home",
      data: data,
    });
  },
};

const mutations = {
  setState(state, { name, data }) {
    Vue.set(state, name, data);
  },
  cleanState(state, { name }) {
    Vue.delete(state, name);
  },
  addLoading(state, { name }) {
    state.isLoading.push(name);
  },
  removeLoading(state, { name }) {
    const idx = state.isLoading.indexOf(name);
    if (idx > -1) {
      state.isLoading.splice(idx, 1);
    }
  },
  addPres2022_2_HistoricGraph(state, { data }) {
    var oldData = state.pres2022_2_historicGraph;
    if (oldData && oldData.datasetValues && data && data.values) {
      oldData.datasetValues.push(data.values);
    }

    if (oldData && oldData.datasetPercentage && data && data.percentages) {
      oldData.datasetPercentage.push(data.percentages);
    }
    Vue.set(state, "pres2022_2_historicGraph", oldData);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
