var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.quotes
    ? _c("div", { staticClass: "banner-trade" }, [
        _c("div", { staticClass: "trade", class: _vm.headerClass }, [
          _c("div", { staticClass: "wrap-trade" }, [
            _c(
              "div",
              {
                staticClass: "playSlideQuotes",
                on: {
                  click: function ($event) {
                    return _vm.toggleQuoteBanner()
                  },
                },
              },
              [
                _c("i", {
                  class: {
                    "icon-play_arrow": !_vm.isPlayingQuoteBanner,
                    "icon-pause": _vm.isPlayingQuoteBanner,
                  },
                }),
              ]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "quote-banner-container" }, [
              _c(
                "div",
                {
                  staticClass: "quote-banner",
                  on: {
                    mouseover: function ($event) {
                      return _vm.overQuoteBanner()
                    },
                    mouseleave: function ($event) {
                      return _vm.leaveQuoteBanner()
                    },
                  },
                },
                [
                  _c(
                    "ul",
                    { staticClass: "list-first" },
                    _vm._l(_vm.quotes, function (quote) {
                      return _c(
                        "li",
                        { key: quote.id },
                        [_c("quote-header", { attrs: { quote: quote } })],
                        1
                      )
                    }),
                    0
                  ),
                  _vm._v(" "),
                  _c(
                    "ul",
                    { staticClass: "list-second" },
                    _vm._l(_vm.quotes, function (quote) {
                      return _c(
                        "li",
                        { key: quote.id },
                        [_c("quote-header", { attrs: { quote: quote } })],
                        1
                      )
                    }),
                    0
                  ),
                ]
              ),
            ]),
            _vm._v(" "),
            _vm._m(0),
          ]),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("a", { attrs: { href: "/indicadores-economicos" } }, [
      _c("div", { staticClass: "ancorQuotes" }, [_vm._v("VER MÁS")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }