<template>
  <div class="banner-trade" v-if="quotes">
    <div class="trade" :class="headerClass">
      <div class="wrap-trade">
        <div class="playSlideQuotes" @click="toggleQuoteBanner()">
          <i
            :class="{
              'icon-play_arrow': !isPlayingQuoteBanner,
              'icon-pause': isPlayingQuoteBanner,
            }"
          ></i>
        </div>
        <div class="quote-banner-container">
          <div
            class="quote-banner"
            @mouseover="overQuoteBanner()"
            @mouseleave="leaveQuoteBanner()"
          >
            <ul class="list-first">
              <li v-for="quote in quotes" :key="quote.id">
                <quote-header :quote="quote" />
              </li>
            </ul>
            <ul class="list-second">
              <li v-for="quote in quotes" :key="quote.id">
                <quote-header :quote="quote" />
              </li>
            </ul>
          </div>
        </div>
        <a href="/indicadores-economicos">
          <div class="ancorQuotes">VER MÁS</div>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import QuoteHeader from "../quote-header/QuoteHeader.vue";
import epica from "../../../features/epica";
export default {
  components: { QuoteHeader },
  props: {
    headerClass: String,
  },
  created: function () {
    this.init();
  },
  updated: function () {
    epica("#vue-header");
  },
  computed: {
    ...mapGetters("master", {
      quotes: "bannerQuotes",
    }),
  },
  data: function () {
    return {
      isPlayingQuoteBanner: false,
    };
  },
  methods: {
    init() {
      setTimeout(() => {
        if (this.quotes) {
          this.toggleQuoteBanner();
        } else {
          this.init();
        }
      }, 2000);
    },
    stopQuoteBanner: function () {
      clearTimeout(qTimeoutId);
      const ll = ComputeLeft();
      const _firstList = document.querySelectorAll(".list-first")[0];
      const _secondList = document.querySelectorAll(".list-second")[0];
      _firstList.classList.remove("move-list-first");
      _secondList.classList.remove("move-list-second");
      _firstList.style.left = ll + "px";
      _secondList.style.left = ll + "px";
    },
    startQuoteBanner: function () {
      const ll = ComputeLeft();
      const _firstList = document.querySelectorAll(".list-first")[0];
      const _secondList = document.querySelectorAll(".list-second")[0];
      _firstList.classList.add("move-list-first");
      _secondList.classList.add("move-list-second");
      _firstList.style.left = ll + "px";
      _secondList.style.left = ll + "px";
      removeLeft(ll);
    },
    overQuoteBanner: function () {
      if (this.isPlayingQuoteBanner) {
        this.stopQuoteBanner();
      }
    },
    leaveQuoteBanner: function () {
      if (this.isPlayingQuoteBanner) {
        this.startQuoteBanner();
      }
    },
    toggleQuoteBanner: function () {
      this.isPlayingQuoteBanner = !this.isPlayingQuoteBanner;
      if (this.isPlayingQuoteBanner) {
        this.startQuoteBanner();
      } else {
        this.stopQuoteBanner();
      }
    },
  },
};

let qTimeoutId = null;
const ComputeLeft = () => {
  const l1 =
    document.querySelectorAll(".list-first")[0].getBoundingClientRect().x -
    document
      .querySelectorAll(".quote-banner-container")[0]
      .getBoundingClientRect().x;
  const l2 =
    document.querySelectorAll(".list-second")[0].getBoundingClientRect().x -
    document
      .querySelectorAll(".quote-banner-container")[0]
      .getBoundingClientRect().x;
  return l1 < 0 && l2 < 0 ? (l1 < l2 ? l2 : l1) : l1 > l2 ? l2 : l1;
};

const removeLeft = (ll) => {
  const _firstList = document.querySelectorAll(".list-first")[0];
  const _secondList = document.querySelectorAll(".list-second")[0];
  const x = _firstList.getBoundingClientRect().width;
  const seconds = ((x + ll) / x) * 50;
  qTimeoutId = setTimeout(function () {
    _firstList.classList.remove("move-list-first");
    _secondList.classList.remove("move-list-second");
    _firstList.style.left = null;
    _secondList.style.left = null;
    setTimeout(function () {
      _firstList.classList.add("move-list-first");
      _secondList.classList.add("move-list-second");
    }, 100);
  }, seconds * 1000);
};
</script>
