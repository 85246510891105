<template>
  <div class="menu-lr-mas" v-click-outside="hideMenu">
    <button
      @click="toggleMenu()"
      :class="{ active: menuIsVisible }"
      :title="'Beneficios Suscriptores'"
    >
      <i class="icon-menu-puntos"></i>
    </button>
    <div class="modal" v-if="menuIsVisible">
      <a href="https://mas.larepublica.co/" target="_blank"
        ><i class="icon-lrmas"></i
      ></a>
      <div class="line"></div>
      <div class="row row-cols-3 justify-content-center">
        <div class="col">
          <a href="https://mas.larepublica.co/noticias" target="_blank">
            <i class="icon-noticias"></i>MI SELECCIÓN DE NOTICIAS
          </a>
        </div>
        <div class="col">
          <a href="https://mas.larepublica.co/10000-empresas" target="_blank">
            <i class="icon-empresas"></i>BITÁCORA EMPRESARIAL 10.000 LR
          </a>
        </div>
        <div class="col">
          <a href="https://mas.larepublica.co/eventos" target="_blank">
            <i class="icon-eventos"></i>CENTRO DE CONVENCIONES
          </a>
        </div>
        <div class="col">
          <a href="https://mas.larepublica.co/libros" target="_blank">
            <i class="icon-libros"></i>KIOSCO
          </a>
        </div>
        <div class="col">
          <a href="https://mas.larepublica.co/version-digital" target="_blank">
            <i class="icon-version-digital"></i>TINTA DIGITAL
          </a>
        </div>
        <div class="col">
          <a
            href="https://mas.larepublica.co/articulos-guardados"
            target="_blank"
          >
            <i class="icon-favoritos"></i>BIBLIOTECA PERSONAL
          </a>
        </div>
        <div class="col">
          <a href="https://mas.larepublica.co/notificaciones" target="_blank">
            <i class="icon-notificacion"></i>NOTIFICACIONES Y ALERTAS
          </a>
        </div>
        <div class="col">
          <a
            href="https://mas.larepublica.co/cortesias"
            target="_blank"
            v-if="!user || (!user.isSubscriber && !user.hasActivedCourtesy)"
          >
            <i class="icon-courtesy"></i>ACTIVAR CORTESÍA
          </a>
        </div>
        <div class="col">
          <a
            href="https://mas.larepublica.co/activar-suscripcion"
            target="_blank"
            v-if="!user || !user.hasActivatedSubscriberId"
          >
            <i class="icon-user-active"></i>ACTIVAR SUSCRIPCIÓN
          </a>
        </div>
        <div class="col full">
          <a
            href="https://mas.larepublica.co/historia-economica"
            target="_blank"
          >
            <i class="icon-book-economy"></i>
            <div>
              <div class="n">Nuevo</div>
              HISTORIA ECONÓMICA DE COLOMBIA
            </div>
          </a>
        </div>
      </div>
      <div class="line" v-if="user && !user.isSubscriber"></div>
      <div class="content-suscriptor" v-if="user && !user.isSubscriber">
        <template v-if="!hasSubscriberId">
          <span
            >Si ya es suscriptor de La República, ingrese su cédula o NIT</span
          >
          <form @submit.prevent="subscribe">
            <div class="input-group">
              <input
                type="text"
                class="form-control"
                v-model="id"
                autocomplete="off"
                placeholder="Ingrese su identificación"
              />
              <button id="documento">
                <i class="icon-check-circle"></i>
              </button>
            </div>
            <span
              v-if="subscribeMessage"
              class="error"
              v-html="subscribeMessage"
            ></span>
          </form>
          <span>O puede <strong>comprar</strong> una suscripción</span>
          <a
            href="https://suscripciones.larepublica.co/?utm_source=landingLR&utm_medium=botonSuscripcion&utm_id=laRepublica"
            target="_blank"
            rel="noopener"
            class="suscribe"
            >SUSCRÍBASE</a
          >
        </template>
        <template v-else>
          <p>
            Lo sentimos, el número de identificación relacionado con su cuenta
            no tiene una suscripción activa.
          </p>
          <p>
            Puede comprar su suscripción aquí o renovarla telefónicamente al.
          </p>
          <strong>(+57-1) 422 7600 Opción: 1-1-2</strong>
          <a
            href="https://suscripciones.larepublica.co/?utm_source=landingLR&utm_medium=botonSuscripcion&utm_id=laRepublica"
            target="_blank"
            rel="noopener"
            class="suscribe"
            >COMPRAR SUSCRIPCIÓN</a
          >
        </template>
      </div>
    </div>
    <div
      class="overlay"
      @click="hideMenu()"
      v-bind:class="{ show: menuIsVisible }"
    ></div>
  </div>
</template>

<script>
import axios from "axios";
import { mapActions, mapGetters } from "vuex";
import { clickOutside } from "../../directives/lr-directives";
import store from "../../store";
clickOutside();
export default {
  watch: {
    menuIsVisible: function (newVal, oldVal) {
      if (newVal) {
        document.body.classList.add("overflow-hidden");
      } else {
        document.body.classList.remove("overflow-hidden");
      }
    },
  },
  data: function () {
    return {
      menuIsVisible: false,
      id: null,
      subscribeMessage: null,
      successMessage: null,
    };
  },
  computed: {
    ...mapGetters("auth", {
      user: "user",
      hasLoaded: "hasLoaded",
      token: "token",
    }),
    hasSubscriberId() {
      return this.user && this.user.subscriberId;
    },
  },
  methods: {
    ...mapActions({
      subscribeUser: "auth/subscribeUser",
    }),
    hideMenu: function () {
      if (this.menuIsVisible) {
        this.menuIsVisible = false;
      }
    },
    toggleMenu: function () {
      this.menuIsVisible = !this.menuIsVisible;
    },
    subscribe: function (e) {
      if (this.id) {
        const _self = this;
        _self.subscribeMessage = null;
        const axiosConfig = {
          headers: {
            Authorization: `Bearer ${this.token}`,
          },
        };
        axios
          .post(
            "/api/lrplus/subscribe",
            {
              identification: this.id,
            },
            axiosConfig
          )
          .then(function (d) {
            if (d.data.success) {
              _self.subscribeUser(_self.id);
              _self.successMessage = "Cuenta activada de forma satisfactoria.";
              setTimeout(() => {
                _self.successMessage = null;
              }, 3000);
            } else {
              _self.subscribeMessage = d.data.errorMessage;
            }
          });
      } else {
        const _self = this;
        _self.subscribeMessage = "El número de identificación es obligatorio";
        setTimeout(() => {
          _self.subscribeMessage = null;
        }, 3000);
      }
    },
  },
};
</script>
