import Keycloak from "keycloak-js";
import axios from "axios";
import lrLocalStorage from "../../../features/lr-localstorage";
import jwt_decode from "jwt-decode";

import { LR_KEYCLOAK } from "../../../constants/constants";

const keycloakConfig = "https://s.lalr.co/keycloak/config/lr-v3.json";
const keycloak = new Keycloak(keycloakConfig);

const state = () => ({
  userBasic: null,
  news: null,
  hasLoaded: false,
  token: null,
  idToken: null,
  kConf: null,
  topics: null,
  favorites: null,
});

const getters = {
  user: (state) => {
    return state.userBasic
      ? {
          completeName: state.userBasic.completeName,
          email: state.userBasic.email,
          isSubscriber: state.userBasic.isSubscriber,
          hasActivatedSubscriberId: state.userBasic.hasActivatedSubscriberId,
          hasActivedCourtesy: state.userBasic.hasActivedCourtesy,
          printName: state.userBasic.printName,
          subscriberId: state.userBasic.subscriberId,
        }
      : null;
  },
  favorites: (state) => {
    return state.favorites;
  },
  topics: (state) => {
    return state.topics;
  },
  news: (state) => {
    return state.news;
  },
  hasLoaded: (state) => {
    return state.hasLoaded;
  },
  token: (state) => {
    return state.token;
  },
};

const actions = {
  init({ dispatch }) {
    keycloak.onAuthLogout = () => {
      dispatch("logout");
    };
  },
  getKConf({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get(keycloakConfig).then((r) => {
        const kConf = r.data;
        commit("setKConf", kConf);
        resolve();
      });
    });
  },
  validateKeycloak({ dispatch, commit }) {
    return new Promise((resolve, reject) => {
      const initKeycloak = JSON.parse(lrLocalStorage.getItem(LR_KEYCLOAK));
      if (initKeycloak) {
        dispatch("validateToken", {
          initKeycloak,
        }).then((r1) => {
          if (r1) {
            commit("setToken", initKeycloak.token);
            commit("setIdToken", initKeycloak.idToken);
            resolve(r1);
          } else {
            commit("setToken", null);
            commit("setIdToken", null);
            lrLocalStorage.removeItem(LR_KEYCLOAK);
            dispatch("validateKeycloak").then((rr1) => resolve(rr1));
          }
        });
      } else {
        dispatch("checkSso").then(() => {
          if (keycloak.authenticated) {
            lrLocalStorage.setItem(
              LR_KEYCLOAK,
              JSON.stringify({
                token: keycloak.token,
                refreshToken: keycloak.refreshToken,
                idToken: keycloak.idToken,
              })
            );
            commit("setToken", keycloak.token);
            commit("setIdToken", keycloak.idToken);
          } else {
            commit("setToken", null);
            commit("setIdToken", null);
            commit("loadEnd");
            window.lrSegment.userLogout();
          }
          resolve(keycloak.authenticated);
        });
      }
    });
  },
  validateToken({ dispatch, state }, { initKeycloak }) {
    return new Promise((resolve, reject) => {
      dispatch("getKConf").then(() => {
        let decoded = null;
        try {
          decoded = jwt_decode(initKeycloak.token);
        } catch (error) {
          resolve(false);
        }
        if (decoded["exp"] * 1000 < Date.now() + 60000) {
          //try to refresh token
          const params = new URLSearchParams();
          params.append("grant_type", "refresh_token");
          params.append("refresh_token", initKeycloak.refreshToken);
          params.append("client_id", state.kConf["resource"]);
          axios
            .post(
              `${state.kConf["auth-server-url"]}realms/${state.kConf["realm"]}/protocol/openid-connect/token`,
              params,
              {
                headers: {
                  "Content-Type": "application/x-www-form-urlencoded",
                },
              }
            )
            .then((r) => {
              const res = r.data;
              lrLocalStorage.setItem(
                LR_KEYCLOAK,
                JSON.stringify({
                  token: res["access_token"],
                  refreshToken: res["refresh_token"],
                  idToken: res["id_token"],
                })
              );
              try {
                decoded = jwt_decode(initKeycloak.token);
              } catch (error) {
                resolve(false);
              }
              resolve(
                decoded["allowed-origins"].indexOf(document.location.origin) >
                  -1 &&
                  decoded["aud"].indexOf(state.kConf["resource"]) > -1 &&
                  decoded["iss"] ===
                    `${state.kConf["auth-server-url"]}realms/${state.kConf["realm"]}`
              );
            })
            .catch(() => {
              resolve(false);
            });
        } else {
          const valid =
            decoded["allowed-origins"].indexOf(document.location.origin) > -1 &&
            decoded["aud"].indexOf(state.kConf["resource"]) > -1 &&
            decoded["iss"] ===
              `${state.kConf["auth-server-url"]}realms/${state.kConf["realm"]}`;
          resolve(valid);
        }
      });
    });
  },
  checkSso() {
    return keycloak.init({
      onLoad: "check-sso",
      silentCheckSsoRedirectUri:
        window.location.origin + "/silent-check-sso.html",
      silentCheckSsoFallback: false,
      checkLoginIframe: false,
    });
  },
  getUserBasic({ dispatch, commit, state }) {
    dispatch("validateKeycloak").then((isAuthenticated) => {
      if (isAuthenticated) {
        const axiosConfig = {
          headers: {
            Authorization: `Bearer ${state.token}`,
          },
        };
        axios
          .get("/api/user", axiosConfig)
          .then(function (d) {
            commit("setUserBasic", d.data);
            commit("loadEnd");
          })
          .catch(() => {
            lrLocalStorage.removeItem(LR_KEYCLOAK);
            dispatch("getUserBasic");
          });
      }
    });
    setTimeout(() => {
      commit("loadEnd");
    }, 5000);
  },
  getUserNews({ commit, dispatch, state }) {
    dispatch("validateKeycloak").then((isAuthenticated) => {
      if (isAuthenticated) {
        const axiosConfig = {
          headers: {
            Authorization: `Bearer ${state.token}`,
          },
        };
        const req1 = axios.get("/api/user", axiosConfig);
        const req2 = axios.get("/api/user/news", axiosConfig);
        axios
          .all([req1, req2])
          .then(
            axios.spread((...resp) => {
              commit("setUserBasic", resp[0].data);
              commit("setUserNews", resp[1].data);
              commit("loadEnd");
            })
          )
          .catch(() => {
            lrLocalStorage.removeItem(LR_KEYCLOAK);
            dispatch("getUserNews");
          });
      }
    });

    setTimeout(() => {
      commit("loadEnd");
    }, 5000);
  },
  getUserFavorites({ commit, dispatch, state }) {
    dispatch("validateKeycloak").then((isAuthenticated) => {
      if (isAuthenticated) {
        const axiosConfig = {
          headers: {
            Authorization: `Bearer ${state.token}`,
          },
        };
        axios
          .get("/api/user/favorites", axiosConfig)
          .then(function (d) {
            commit("setUserFavorites", d.data);
          })
          .catch(() => {
            lrLocalStorage.removeItem(LR_KEYCLOAK);
            dispatch("getUserFavorites");
          });
      }
    });
  },
  getUserTopics({ commit, dispatch, state }) {
    dispatch("validateKeycloak").then((isAuthenticated) => {
      if (isAuthenticated) {
        const axiosConfig = {
          headers: {
            Authorization: `Bearer ${state.token}`,
          },
        };
        axios
          .get("/api/user/topics", axiosConfig)
          .then(function (d) {
            commit("setUserTopics", d.data);
          })
          .catch(() => {
            lrLocalStorage.removeItem(LR_KEYCLOAK);
            dispatch("getUserTopics");
          });
      }
    });
  },
  login() {
    const loginUrl = keycloak.createLoginUrl();
    document.location.href = loginUrl;
  },
  register() {
    const loginUrl = keycloak.createLoginUrl({
      action: "register",
    });
    document.location.href = loginUrl;
  },
  logout({ state, dispatch }) {
    if (state.kConf) {
      lrLocalStorage.removeItem(LR_KEYCLOAK);
      document.location.href = `${state.kConf["auth-server-url"]}realms/${
        state.kConf["realm"]
      }/protocol/openid-connect/logout?post_logout_redirect_uri=${encodeURI(
        document.location.href
      )}&id_token_hint=${encodeURI(state.idToken)}`;
    } else {
      dispatch("getKConf").then(() => {
        lrLocalStorage.removeItem(LR_KEYCLOAK);
        document.location.href = `${state.kConf["auth-server-url"]}realms/${
          state.kConf["realm"]
        }/protocol/openid-connect/logout?post_logout_redirect_uri=${encodeURI(
          document.location.href
        )}&id_token_hint=${encodeURI(state.idToken)}`;
      });
    }
    window.lrSegment.userLogout();
  },
  addFavorite({ commit }, favorite) {
    commit("addFavorite", favorite);
  },
  addTopic({ commit }, topic) {
    commit("addTopic", topic);
  },
  removeFavorite({ commit }, id) {
    commit("removeFavorite", id);
  },
  subscribeUser({ commit, getters }, id) {
    commit("subscribe", id);
  },
};

const mutations = {
  addFavorite(state, favorite) {
    state.favorites = state.favorites ? state.favorites : [];
    state.favorites.push(favorite);
    window.lrSegment.addArticle(favorite.postId, favorite.postTitle);
  },
  addTopic(state, topic) {
    state.topics = state.topics ? state.topics : [];
    state.topics.push(topic);
    window.lrSegment.addTopic(topic.topicName);
  },
  removeFavorite(state, id) {
    state.favorites = state.favorites.filter((q) => q.postId != id);
    window.lrSegment.removeArticle(id);
  },
  setUserBasic(state, userBasic) {
    state.userBasic = userBasic;
    window.lrSegment.userLogin(userBasic.epicaUser.id, {
      isSubscriber: userBasic.epicaUser.isSubscriber,
      email: userBasic.epicaUser.email,
      firstName: userBasic.epicaUser.firstName,
      lastName: userBasic.epicaUser.lastName,
      economicActivity: userBasic.epicaUser.economicActivity,
      educationalLevel: userBasic.epicaUser.educationLevel,
      industry: userBasic.epicaUser.industry,
      phone: userBasic.epicaUser.phoneNumber,
      position: userBasic.epicaUser.position,
    });
  },
  setUserTopics(state, topics) {
    state.topics = topics;
  },
  setUserFavorites(state, favorites) {
    state.favorites = favorites;
  },
  setUserNews(state, userNews) {
    state.news = userNews;
  },
  setToken(state, token) {
    state.token = token;
  },
  setIdToken(state, idToken) {
    state.idToken = idToken;
  },
  subscribe(state, id) {
    state.userBasic.subscriberId = id;
    state.userBasic.isSubscriber = true;
  },
  setKConf(state, kConf) {
    state.kConf = kConf;
  },
  loadEnd(state) {
    state.hasLoaded = true;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
