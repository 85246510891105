import Vue from "vue";
import { mapGetters } from "vuex";
import RefreshHub from "./../../features/lr-websockets";
import epica from "../../features/epica";
import store from "../store";

export const initMain = (mixins, components, plugins) => {
  Vue.use(RefreshHub);
  if (plugins) {
    plugins.forEach((plugin) => {
      Vue.use(plugin);
    });
  }
  const mainSelector = "#vue-container";
  return new Vue({
    store,
    el: mainSelector,
    mixins: mixins,
    components: components,
    computed: {
      ...mapGetters("live", {
        liveOn: "isOn",
      }),
      ...mapGetters("elections", {
        electionsBannerOn: "isEnabled",
      }),
    },
    mounted: function () {
      setTimeout(() => {
        window.cleanLoading();
        window.loadAds();
      }, 300);
      epica();
    },
  });
};
