<template>
  <a
    href="/en-vivo"
    class="live show d-flex flex-row"
    v-if="live"
    data-mrf-recirculation="Banner En Vivo"
  >
    <div class="icon-live">
      <img
        src="https://s.lalr.co/lr/images/icon-live-gif.gif"
        alt="Inside"
      /><span>EN VIVO</span>
    </div>
    <div class="flex-grow-1 content align-items-center">
      <div class="line"></div>
      <img
        alt="Logo Inside"
        v-if="live.isInside"
        class="img-inside"
        src="https://s.lalr.co/lr/images/logos/logo-Inside.png"
      />
      <!-- <span
        class="type"
        v-if="!live.isInside"
        v-html="live.principalTitle"
      ></span> -->
      <span class="tittle" v-html="live.title"></span>
    </div>
    <div class="btn">VER AHORA</div>
  </a>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  props: {
    loadLive: Boolean,
  },
  created: function () {
    this.init();
    if (this.loadLive) {
      this.getLive();
    }
  },
  computed: {
    ...mapGetters("live", {
      live: "data",
    }),
  },
  methods: {
    ...mapActions("live", {
      init: "initLite",
      getLive: "getLiveLite",
    }),
  },
};
</script>
