<template>
    <div class="elections-2023-banner-lite" v-if="title && !liveOn"
        style="background-image: url('https://s.lalr.co/__assets/lr/images/elecciones-2023/banner-elecciones-2023.jpg')">
        <div class="img-wrap ml-5">
            <img src="https://s.lalr.co/__assets/lr/images/elecciones-2023/logo-elecciones-2023-h.svg"
                alt="Elecciones Territoriales 2023">
        </div>
        <span class="title" v-html="title"></span>
        <div class="results">
            <div class="bulletin" v-if="bulletin">
                <i class="icon-bell-circle"></i>
                <div v-html="bulletin"></div>
            </div>
            <a href="/elecciones-territoriales-2023" class="view-more">ver resultados en vivo <i
                    class="icon-arrow-right"></i></a>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
export default {
    created() {
        this.$refreshHub.$on("banner-elections", this.newInfo);
        this.loadBanner();
    },
    computed: {
        ...mapGetters("live", {
            liveOn: "isOn"
        }),
    },
    methods: {
        controlDarkHeader: function () {
            const darkHeader = document.querySelector("header.header-dark");
            if (darkHeader) {
                var cSheetElec = document.createElement('style')
                cSheetElec.innerHTML = `#vue-container:not(.live-on),
                    #vue-container:not(.live-on) .homeIndicadores .menuLateral,
                    #vue-container:not(.live-on) .InternaIndicadores .menuLateral,
                    #vue-container:not(.live-on) .menuLateralInside {
                        padding-top: 42px;
                    }
                    #vue-container:not(.live-on) .btMenuLateral {
                        margin-top: 42px;
                    }`;
                document.body.appendChild(cSheetElec);
            }
        },
        newInfo: function (data) {
            var _self = this;
            _self.bulletin = data;
            if (this.toId) {
                clearTimeout(this.toId);
            }
            this.toId = setTimeout(() => {
                _self.bulletin = null;
            }, 30000);
        },
        loadBanner: function () {
            this.loading = true;
            var _self = this;
            axios
                .get("/api/elections/2023/banner")
                .then(function (d) {
                    _self.loading = false;
                    if (d.data.success && d.data.opening.feedTitle) {
                        _self.title = d.data.opening.feedTitle;
                        _self.controlDarkHeader();
                    }
                });
        }
    },
    data: function () {
        return {
            bulletin: null,
            title: null,
            loading: false,
            toId: null
        };
    },
};
</script>