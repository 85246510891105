var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "click-outside",
          rawName: "v-click-outside",
          value: _vm.hideMenu,
          expression: "hideMenu",
        },
      ],
      staticClass: "user-login",
    },
    [
      _c(
        "button",
        {
          staticClass: "login-btn",
          class: { active: _vm.menuIsVisible },
          attrs: {
            title: _vm.user ? _vm.user.completeName : "Ingrese o Regístrese",
          },
          on: {
            click: function ($event) {
              return _vm.toggleMenu()
            },
          },
        },
        [_c("i", { staticClass: "icon-user" })]
      ),
      _vm._v(" "),
      _vm.menuIsVisible
        ? _c(
            "div",
            { staticClass: "modal" },
            [
              _vm.hasLoaded
                ? [
                    _vm.user
                      ? [
                          _c("div", { staticClass: "user-drop-menu" }, [
                            _c("span", {
                              staticClass: "user",
                              attrs: { title: _vm.user.completeName },
                              domProps: {
                                innerHTML: _vm._s(_vm.user.printName),
                              },
                            }),
                            _vm._v(" "),
                            _c("div", { staticClass: "line" }),
                            _vm._v(" "),
                            _vm._m(0),
                            _vm._v(" "),
                            _vm.successMessage
                              ? _c("div", {
                                  staticClass: "success-message",
                                  domProps: {
                                    innerHTML: _vm._s(_vm.successMessage),
                                  },
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            !_vm.user.isSubscriber
                              ? _c(
                                  "div",
                                  { staticClass: "content-suscriptor" },
                                  [
                                    !_vm.hasSubscriberId
                                      ? [
                                          _c("span", [
                                            _vm._v(
                                              "Si ya es suscriptor de La República, ingrese su cédula o\n                NIT"
                                            ),
                                          ]),
                                          _vm._v(" "),
                                          _c(
                                            "form",
                                            {
                                              on: {
                                                submit: function ($event) {
                                                  $event.preventDefault()
                                                  return _vm.subscribe.apply(
                                                    null,
                                                    arguments
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "input-group" },
                                                [
                                                  _c("input", {
                                                    directives: [
                                                      {
                                                        name: "model",
                                                        rawName: "v-model",
                                                        value: _vm.id,
                                                        expression: "id",
                                                      },
                                                    ],
                                                    staticClass: "form-control",
                                                    attrs: {
                                                      type: "text",
                                                      autocomplete: "off",
                                                      placeholder:
                                                        "Ingrese su identificación",
                                                    },
                                                    domProps: { value: _vm.id },
                                                    on: {
                                                      input: function ($event) {
                                                        if (
                                                          $event.target
                                                            .composing
                                                        ) {
                                                          return
                                                        }
                                                        _vm.id =
                                                          $event.target.value
                                                      },
                                                    },
                                                  }),
                                                  _vm._v(" "),
                                                  _vm._m(1),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _vm.subscribeMessage
                                                ? _c("span", {
                                                    staticClass: "error",
                                                    domProps: {
                                                      innerHTML: _vm._s(
                                                        _vm.subscribeMessage
                                                      ),
                                                    },
                                                  })
                                                : _vm._e(),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _vm._m(2),
                                          _vm._v(" "),
                                          _c(
                                            "a",
                                            {
                                              staticClass: "suscribe",
                                              attrs: {
                                                href: "https://suscripciones.larepublica.co/?utm_source=landingLR&utm_medium=botonSuscripcion&utm_id=laRepublica",
                                                target: "_blank",
                                                rel: "noopener",
                                              },
                                            },
                                            [_vm._v("SUSCRÍBASE")]
                                          ),
                                        ]
                                      : [
                                          _c("p", [
                                            _vm._v(
                                              "\n                Lo sentimos, el número de identificación relacionado con su\n                cuenta no tiene una suscripción activa.\n              "
                                            ),
                                          ]),
                                          _vm._v(" "),
                                          _c("p", [
                                            _vm._v(
                                              "\n                Puede comprar su suscripción aquí o renovarla telefónicamente\n                al.\n              "
                                            ),
                                          ]),
                                          _vm._v(" "),
                                          _c("strong", [
                                            _vm._v(
                                              "(+57-1) 422 7600 Opción: 1-1-2"
                                            ),
                                          ]),
                                          _vm._v(" "),
                                          _c(
                                            "a",
                                            {
                                              staticClass: "suscribe",
                                              attrs: {
                                                href: "https://suscripciones.larepublica.co/?utm_source=landingLR&utm_medium=botonSuscripcion&utm_id=laRepublica",
                                                target: "_blank",
                                                rel: "noopener",
                                              },
                                            },
                                            [_vm._v("COMPRAR SUSCRIPCIÓN")]
                                          ),
                                        ],
                                  ],
                                  2
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "button",
                              {
                                staticClass: "logout",
                                on: {
                                  click: function ($event) {
                                    return _vm.logout()
                                  },
                                },
                              },
                              [
                                _vm._v("\n            CERRAR SESIÓN"),
                                _c("i", { staticClass: "icon-exit" }),
                              ]
                            ),
                          ]),
                        ]
                      : [
                          _c("div", { staticClass: "login" }, [
                            _c(
                              "button",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.login()
                                  },
                                },
                              },
                              [
                                _c("i", { staticClass: "icon-user" }),
                                _vm._v("INICIAR SESIÓN\n          "),
                              ]
                            ),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "registre" }, [
                            _c(
                              "button",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.register()
                                  },
                                },
                              },
                              [
                                _c("i", { staticClass: "icon-user-add" }),
                                _vm._v("REGÍSTRESE\n          "),
                              ]
                            ),
                          ]),
                        ],
                  ]
                : [_c("div", { staticClass: "loading" })],
            ],
            2
          )
        : _vm._e(),
      _vm._v(" "),
      _c("div", {
        staticClass: "overlay",
        class: { show: _vm.menuIsVisible },
        on: {
          click: function ($event) {
            return _vm.hideMenu()
          },
        },
      }),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "profile" }, [
      _c("a", { attrs: { href: "//auth.lalr.co" } }, [
        _c("i", { staticClass: "icon-user" }),
        _vm._v("VER PERFÍL"),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("button", { attrs: { id: "documento" } }, [
      _c("i", { staticClass: "icon-check-circle" }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", [
      _vm._v("O puede "),
      _c("strong", [_vm._v("comprar")]),
      _vm._v(" una suscripción"),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }