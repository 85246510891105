var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.hideBanner
    ? _c("div", { staticClass: "cookies" }, [
        _c("div", { staticClass: "container" }, [
          _c("div", { staticClass: "title" }, [_vm._v("COOKIES")]),
          _vm._v(" "),
          _c("div", { staticClass: "d-flex align-items-center" }, [
            _c("p", [
              _vm._v(
                "\n        Este sitio web usa cookies propias y de terceros para analizar el\n        tráfico, mejorar nuestros servicio y conocer el comportamiento del\n        usuario. Para conocer más, acceda a nuestra\n        "
              ),
              _c("a", { attrs: { href: _vm.cookiesPolicyUrl } }, [
                _vm._v("Política de Cookies"),
              ]),
              _vm._v(".\n      "),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "flex-grow-1" }, [
              _c(
                "span",
                {
                  staticClass: "action",
                  on: {
                    click: function ($event) {
                      return _vm.hasAceptedCookiesPolicy()
                    },
                  },
                },
                [_vm._v("ENTENDIDO")]
              ),
            ]),
          ]),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }