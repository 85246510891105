import {
    initMain
} from './vue/controllers/main';
import {
    header
} from './vue/controllers/header';
import {
    TextFill,
    tfmixin
} from './vue/components/text-fill/TextFill.vue';

import lazyload from './features/lazy-load';


lazyload();

const mixins = [];
mixins.push(tfmixin);

initMain(mixins, {
    TextFill
});
header();