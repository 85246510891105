var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.title && !_vm.liveOn
    ? _c(
        "div",
        {
          staticClass: "elections-2023-banner-lite",
          staticStyle: {
            "background-image":
              "url('https://s.lalr.co/__assets/lr/images/elecciones-2023/banner-elecciones-2023.jpg')",
          },
        },
        [
          _vm._m(0),
          _vm._v(" "),
          _c("span", {
            staticClass: "title",
            domProps: { innerHTML: _vm._s(_vm.title) },
          }),
          _vm._v(" "),
          _c("div", { staticClass: "results" }, [
            _vm.bulletin
              ? _c("div", { staticClass: "bulletin" }, [
                  _c("i", { staticClass: "icon-bell-circle" }),
                  _vm._v(" "),
                  _c("div", { domProps: { innerHTML: _vm._s(_vm.bulletin) } }),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm._m(1),
          ]),
        ]
      )
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "img-wrap ml-5" }, [
      _c("img", {
        attrs: {
          src: "https://s.lalr.co/__assets/lr/images/elecciones-2023/logo-elecciones-2023-h.svg",
          alt: "Elecciones Territoriales 2023",
        },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "a",
      {
        staticClass: "view-more",
        attrs: { href: "/elecciones-territoriales-2023" },
      },
      [
        _vm._v("ver resultados en vivo "),
        _c("i", { staticClass: "icon-arrow-right" }),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }