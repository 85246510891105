"use strict";
import {
    HubConnectionBuilder,
    LogLevel,
    HttpTransportType
} from '@microsoft/signalr'
export default {
    install(Vue) {
        const connection = new HubConnectionBuilder()
            .withUrl('https://sock.larepublica.co/refresh', {
                transport: HttpTransportType.WebSockets,
                skipNegotiation: true
            })
            // .withUrl('http://dsock.larepublica.co:12344/refresh')
            .configureLogging(LogLevel.Error)
            .withAutomaticReconnect()
            .build();
        connection.serverTimeoutInMilliseconds = 1000 * 90;
        connection.keepAliveIntervalInMilliseconds = 1000 * 45;
        const refreshHub = new Vue();
        Vue.prototype.$refreshHub = refreshHub;
        connection.on('RefreshHome', () => {
            refreshHub.$emit('home-refreshed');
        });
        connection.on('RefreshLiveLR_LR', () => {
            refreshHub.$emit('live-update');
        });
        connection.on('AtentoUpdate', () => {
            refreshHub.$emit('atento-update');
        });
        connection.on('HomeElection', (data) => {
            refreshHub.$emit('home-elections', data);
        });
        connection.on('BannerElection', (data) => {
            refreshHub.$emit('banner-elections', data);
        });
        connection.start();
    }
}