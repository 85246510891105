"use strict";

export const adexecute = () => {
  let lazyAds = [].slice.call(
    document.querySelectorAll('div[id^="div-gpt-ad-"]:not(.p)')
  );
  if ("IntersectionObserver" in window) {
    const lazyObjectObserver = new IntersectionObserver(
      function (entries, observer) {
        entries.forEach(function (entry) {
          if (entry.isIntersecting) {
            const lazyAd = entry.target;
            if (!lazyAd.classList.contains("p")) {
              lazyAd.classList.add("p");
              if (window.enabledAdUnits.indexOf(lazyAd.id) > -1) {
                googletag.display(lazyAd.id);
              }
              lazyObjectObserver.unobserve(lazyAd);
            }
          }
        });
      },
      {
        rootMargin: "0px 0px 256px 0px",
      }
    );
    lazyAds.forEach(function (lazyImage) {
      lazyObjectObserver.observe(lazyImage);
    });
  }
};
export const adlazyload = () => {
  if (!window.initAds) {
    window.initAds = () => {
      if (window.enabledAdUnits) {
        adexecute();
      } else {
        setTimeout(() => {
          window.initAds();
        }, 100);
      }
    };
  }
};
