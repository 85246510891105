import Vue from 'vue';
import Vuex from 'vuex';
import auth from './modules/auth';
import tf from './modules/tf';
import live from './modules/live';
import master from './modules/master';
import webStories from './modules/web-stories';
import elections from './modules/elections';

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        auth,
        tf,
        live,
        master,
        webStories,
        elections
    }
})