import Vue from 'vue';
import {
    mapActions,
    mapGetters
} from 'vuex';
import SearchBox from './../components/search-box/SearchBox.vue';
import LiveBanner from './../components/live-banner/LiveBanner.vue';
import CookiesBanner from './../components/cookies-banner/CookiesBanner.vue';
import QuoteHeaderBanner from './../components/quote-header-banner/QuoteHeaderBanner.vue';
import UserHeaderButton from './../components/user-header-button/UserHeaderButton.vue';
import MenuLrPlusHeader from './../components/menu-lr-plus-header/MenuLrPlusHeader.vue';
import BannerElections2023 from './../components/banner-elections-2023/BannerElections2023.vue';
import epica from '../../features/epica';
import {
    helpers
} from '../../features/epica';

import store from '../store';
export const header = (mixins) => {
    helpers();
    mixins = mixins ? mixins : [{
        created() {
            this.userBasic();
        }
    }];
    const headerSelector = '#vue-header';
    window.vueHeader = new Vue({
        el: headerSelector,
        mixins: mixins,
        store,
        components: {
            SearchBox,
            CookiesBanner,
            LiveBanner,
            QuoteHeaderBanner,
            UserHeaderButton,
            MenuLrPlusHeader,
            BannerElections2023
        },
        created() {
            this.init();
            this.masterInit();
            let last_known_scroll_position = 0;
            let ticking = false;
            const _self = this;
            window.addEventListener('scroll', function (e) {
                last_known_scroll_position = window.scrollY;
                if (!ticking) {
                    window.requestAnimationFrame(function () {
                        _self.handleScroll(last_known_scroll_position);
                        ticking = false;
                    });
                }
                ticking = true;
            });
        },
        mounted: function () {
            this.validateHasLoaded();
        },
        watch: {
            isMenuShow: function (newVal, oldVal) {
                if (newVal) {
                    document.body.classList.add("overflow-hidden");
                } else {
                    document.body.classList.remove("overflow-hidden");
                }
            },
            searchBoxIsOpen: function (newVal, oldVal) {
                if (newVal) {
                    document.body.classList.add("overflow-hidden");
                } else {
                    document.body.classList.remove("overflow-hidden");
                }
            }
        },
        data: function () {
            return {
                isMenuShow: false,
                isHeaderFixed: window.scrollY > 200,
                searchBoxIsOpen: false
            };
        },
        computed: {
            ...mapGetters("auth", {
                user: "user",
                hasLoaded: "hasLoaded",
            }),
        },
        methods: {
            ...mapActions({
                init: 'auth/init',
                userBasic: 'auth/getUserBasic',
                userNews: 'auth/getUserNews',
                masterInit: 'master/init',
                login: "auth/login",
                logout: "auth/logout"
            }),

            
            closeMenu: function () {
                this.isMenuShow = false;
            },
            showMenu: function () {
                this.isMenuShow = true;
            },
            handleScroll: function (scrollY) {
                this.isHeaderFixed = scrollY > 200;
            },
            validateHasLoaded: function () {
                const _self = this;
                if (_self.hasLoaded) {
                    epica(headerSelector);
                } else {
                    setTimeout(() => {
                        _self.validateHasLoaded();
                    }, 400);
                }
            }
        }
    });
}