import Vue from 'vue';
import axios from 'axios';

const state = () => ({
    take: 0,
    skip: 0,
    total: 0,
    category: null,
    items: [],
    initialTotal: 0,
    loading: false
});

const getters = {
    items: (state) => {
        return state.items;
    },
    pagerEnabled: (state) => {
        return state.total > (state.skip + state.take) && !state.loading;
    }

};

const mutations = {
    addItems(state, items) {
        Vue.set(state, "items", state.items.concat(items));
    },
    setItems(state, items) {
        Vue.set(state, "items", items);
    },
    setState(state, {
        name,
        value
    }) {
        Vue.set(state, name, value);
    },
};

const actions = {
    init({
        commit
    }, {
        take,
        offset,
        total
    }) {
        commit('setState', {
            name: "take",
            value: take
        });
        commit('setState', {
            name: "initialTotal",
            value: total
        });
        commit('setState', {
            name: "total",
            value: total
        });
    },
    moreResults({
        commit,
        state,
        dispatch
    }) {
        commit('setState', {
            name: "skip",
            value: state.skip + state.take
        });

        dispatch('doSearch');
    },
    changeCategory({
        commit,
        state,
        dispatch
    }, category) {
        commit('setState', {
            name: "category",
            value: category
        });
        if (category) {
            commit('setState', {
                name: "skip",
                value: 0
            });
            commit('setState', {
                name: "total",
                value: null
            });
            dispatch('doSearch');
        } else {
            commit('setState', {
                name: "skip",
                value: 0
            });
            commit('setState', {
                name: "total",
                value: state.initialTotal
            });
            commit('setItems', []);
        }
    },
    doSearch({
        commit,
        state
    }) {

        commit('setState', {
            name: "loading",
            value: true
        });
        axios
            .get("/api/pager/web-stories", {
                params: {
                    category: state.category,
                    take: state.take,
                    offset: state.skip,
                },
            })
            .then(function (d) {
                if (state.skip) {
                    commit('addItems', d.data.items);
                } else {
                    commit('setItems', d.data.items);
                    commit('setState', {
                        name: "total",
                        value: d.data.total
                    });
                }

                commit('setState', {
                    name: "loading",
                    value: false
                });

            });
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}