'use strict';

const epicaClick = (e) => {
    if (e.currentTarget.href.indexOf('#') === -1 && e.currentTarget.href.indexOf('void(0)') === -1) {
        const moduleName = e.currentTarget.closest('[data-epica-module-name]').dataset.epicaModuleName;
        const data = {
            'event': 'epicaClick',
            'epicaClickData': {
                x: e.pageX,
                y: e.pageY,
                moduleName: moduleName,
                href: e.currentTarget.href,
                referer: document.location.href,
                origin: "La República"
            },
            'gaClickData': {
                hitType: 'event',
                eventAction: 'click',
                eventCategory: moduleName,
                eventLabel: document.location.href,
                transport: 'beacon'
            }
        };
        dataLayer.push({
            'event': 'epicaClick',
            'epicaClickData': {
                x: e.pageX,
                y: e.pageY,
                moduleName: moduleName,
                href: e.currentTarget.href,
                referer: document.location.href,
                origin: "La República"
            },
            'gaClickData': {
                hitType: 'event',
                eventAction: 'click',
                eventCategory: moduleName,
                eventLabel: document.location.href,
                transport: 'beacon'
            }
        });
    }
};

const showEpicaModules = () => {
    var sheet = document.createElement('style');
    sheet.setAttribute("id", "styleSheetEpicaM");
    sheet.innerHTML = "[data-epica-module-name] { border: 3px solid #313131 } [data-epica-module-name]::before { content: attr(data-epica-module-name); display: block; height: 25px; background-color: #313131; color: #ccc; position: absolute; font-size: 1.25rem; z-index: 10000000;}";
    document.body.appendChild(sheet);
};

const hideEpicaModules = () => {
    var sheetToBeRemoved = document.getElementById('styleSheetEpicaM');
    var sheetParent = sheetToBeRemoved.parentNode;
    sheetParent.removeChild(sheetToBeRemoved);
};

export const epicaIdentify = (user) => {
    dataLayer.push({
        'event': 'epicaIdentify',
        'identifyData': user
    });
};

export const helpers = () => {
    window.showEpicaModules = showEpicaModules;
    window.hideEpicaModules = hideEpicaModules;
}

export default (p) => {
    p = p ? p + ' ' : '';
    const epicaAnchors = document.querySelectorAll(p + '[data-epica-module-name] a[href]');
    epicaAnchors.forEach(function (el) {
        el.addEventListener('click', epicaClick);
    });
}