import Vue from 'vue';
import axios from 'axios';
const state = () => ({
    trends: null,
    bannerQuotes: false
});

const getters = {
    trends: (state) => {
        return state.trends;
    },
    bannerQuotes: (state) => {
        return state.bannerQuotes;
    }
};

const actions = {
    init({
        commit
    }) {
        axios.get('/api/master').then(q => {
            commit('setMaster', {
                data: q.data
            });
        })
    }
};

const mutations = {
    setMaster(state, {
        data
    }) {
        Vue.set(state, "trends", data.trends);
        Vue.set(state, "bannerQuotes", data.bannerQuotes);
    }
};


export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}