var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.live
    ? _c(
        "a",
        {
          staticClass: "live show d-flex flex-row",
          attrs: {
            href: "/en-vivo",
            "data-mrf-recirculation": "Banner En Vivo",
          },
        },
        [
          _vm._m(0),
          _vm._v(" "),
          _c("div", { staticClass: "flex-grow-1 content align-items-center" }, [
            _c("div", { staticClass: "line" }),
            _vm._v(" "),
            _vm.live.isInside
              ? _c("img", {
                  staticClass: "img-inside",
                  attrs: {
                    alt: "Logo Inside",
                    src: "https://s.lalr.co/lr/images/logos/logo-Inside.png",
                  },
                })
              : _vm._e(),
            _vm._v(" "),
            _c("span", {
              staticClass: "tittle",
              domProps: { innerHTML: _vm._s(_vm.live.title) },
            }),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "btn" }, [_vm._v("VER AHORA")]),
        ]
      )
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "icon-live" }, [
      _c("img", {
        attrs: {
          src: "https://s.lalr.co/lr/images/icon-live-gif.gif",
          alt: "Inside",
        },
      }),
      _c("span", [_vm._v("EN VIVO")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }